import React from 'react';
import {I18nextProvider} from 'react-i18next';
import {PageContextProvider} from './src/context/pageContext';
import {ConfigContextProvider} from './src/context/configContext';
import i18n from './src/language/i18next';

export const wrapRootElement = ({element}) => {
  return <I18nextProvider i18n={i18n}>{element}</I18nextProvider>;
};

export const wrapPageElement = ({element, props}) => {
  return (
    <PageContextProvider pageContext={props.pageContext}>
      <ConfigContextProvider pageContext={props.pageContext}>
        {element}
      </ConfigContextProvider>
    </PageContextProvider>
  );
};
