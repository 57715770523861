const translations = {
  es: {
    translation: {
      siteMetadata: {
        title: 'Consigueme Un Prestamo',
        titleTemplate: 'Camino Financial',
      },
      solopreneur: {
        url: 'https://www.caminofinancial.com/es/prestamo-solopreneur/',
      },
      gml10: {
        topNav: {
          link1: {
            label: 'Apoyo COVID-19',
            url: `${process.env.GATSBY_CAMINO_URL}/es/covid-19/`,
          },
          link1nav: {
            link1: {
              label: 'SBA Programa Protección de Nómina (PPP)',
              url: `${process.env.GATSBY_CAMINO_URL}/es/el-programa-de-la-sba-de-proteccion-a-las-nominas-ppp-ayuda-covid-19/`,
            },
            link2: {
              label: 'Préstamos por Daños Económicos por Desastres (EIDL)',
              url: `${process.env.GATSBY_CAMINO_URL}/es/eidl-los-prestamos-de-danos-economicos-por-desastres-de-la-sba-ayuda-covid-19/`,
            },
            link3: {
              label: 'Más apoyo COVID-19',
              url: `${process.env.GATSBY_CAMINO_URL}/es/covid-19/`,
            },
          },
          link2: {
            label: 'Préstamos de Negocios',
            url: `${process.env.GATSBY_CAMINO_URL}/es/prestamos-para-pequenas-empresas/`,
          },
          link3: {
            label: 'Recursos',
            url: `${process.env.GATSBY_CAMINO_URL}/es/blog/`,
          },
          link3nav: {
            link1: {
              label: 'Financiamiento de Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/financiamiento-de-negocios/`,
            },
            link2: {
              label: 'Contabilidad y Flujo de Caja',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/contabilidad-y-flujo-de-caja/`,
            },
            link3: {
              label: 'Comenzar un Negocio',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/comenzar-un-negocio/`,
            },
            link4: {
              label: 'Marketing & Ventas',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/marketing-ventas/`,
            },
            link5: {
              label: 'Administración de Empresas',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/administracion-de-empresas/`,
            },
            link6: {
              label: 'Tecnología y Operaciones',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/tecnologia-y-operaciones/`,
            },
            link7: {
              label: 'Noticias en La Comunidad',
              url: `${process.env.GATSBY_CAMINO_URL}/es/category/noticias-en-la-comunidad/`,
            },
            link8: {
              label: 'Encuesta Pequeños Negocios Latino',
              url: `${process.env.GATSBY_CAMINO_URL}/es/encuesta-pequenos-negocios-latino/`,
            },
          },
          link4: {
            label: 'Historias de Éxito',
            url: `${process.env.GATSBY_CAMINO_URL}/es/success-stories/`,
          },
          link5: {
            label: 'Quiénes Somos',
            url: `${process.env.GATSBY_CAMINO_URL}/es/sobre-nosotros/`,
          },
          link6: {
            label: 'INICIAR SESIÓN',
            url: `${process.env.GATSBY_MY_CAMINO_URL}/signin`,
          },
          link7: {
            label: 'APLICAR HOY',
            url: ``,
          },
          link8: {label: 'ENGLISH', url: 'http:'},
        },
        hero: {
          title1: 'Préstamos perfectos,',
          title2: 'pagos predecibles',
          subtitle:
            'Apoyamos a empresas y emprendedores con sólo un ITIN, eliminando la necesidad de colateral o garantías y aceptando incluso aquellos sin historial de crédito.',
          media_presence: 'Como se has visto en los medios',
        },
        reviews: {
          title:
            'Empoderando a las pequeñas empresas para que alcancen el éxito',
        },
        calculator: {
          title: 'Proyecta tus pagos con facilidad',
          loan_amount: 'CAPITAL DESEADO',
          payment_term: 'NÚMERO DE CUOTAS',
          monthly_interest_rate: 'INTERÉS MENSUAL',
          monthly: 'MENSUALES',
          months: 'MESES',
          total_cost_of_loan: 'COSTO TOTAL DEL PRÉSTAMO',
          funding_fee: 'CARGO POR TRANSFERENCIA',
          documentation_fee: 'CARGO POR PAPELEO',
          closing_fee: 'CARGO POR CIERRE',
          total_interest_paid: 'TOTAL INTERESES A PAGAR',
          see_more: 'VER MÁS INFO',
          see_less: 'VER MENOS INFO',
          check_if_you_qualify: 'DESCUBRE SI CALIFICAS',
        },
        stats: {
          title1: 'Estamos',
          title2: 'potenciando el desarrollo económico latino',
          title3: 'por medio de',
          number1: '$200M+',
          description1: 'de dolares préstados',
          number2: '10,341+',
          description2: 'préstamos (90% para empresarios desatendidos)',
          number3: '$109M+',
          description3:
            'de dólares de ingresos adicionales para nuestros miembros',
          number4: '$19.5M',
          description4: 'ahorrados en intereses y comisiones',
        },
        feats: {
          title1: 'Financiamiento equitativo:',
          title2: 'Cuando los bancos te excluyen, nosotros te incluimos',
          feature1: 'Inmigrantes indocumentados',
          feature2: 'Individuos sin historial crediticio',
          feature3: 'Empresas con sólo un empleado',
          feature4: 'Propietarios únicos',
          feature5: 'Negocios informales',
        },
        partnerModal: {
          title: 'Serás redirigido a One Main Financial',
          subtitle:
            'Basándonos en tu solicitud, creemos que un préstamo personal es la mejor opción para tus necesidades.',
          description:
            'Nos hemos aliado con One Main Financial, un proveedor confiable de préstamos personales que cuenta con más de 1,700 sucursales en 44 estados. Camino Financial recibe una compensación por dirigir clientes potenciales y financiados por One Main Financial. No somos responsables de las tarifas, autorizaciones de crédito y decisiones tomadas por One Main Financial.',
          description2:
            'Divulgación: Camino Financial obtiene una remuneración de sus socios prestamistas. Camino Financial no participa en actividades crediticias ni origina préstamos personales. La responsabilidad de las decisiones crediticias, incluida la aprobación del préstamo, las tasas de interés y los términos ofrecidos, recae únicamente en los socios prestamistas. Estas decisiones variarán según la solicitud de préstamo, la situación financiera del prestatario y los criterios establecidos por los socios prestamistas. Cabe señalar que no todas las personas cumplirán con los criterios de elegibilidad para las tasas y términos anunciados.',
          cta1: '¡Llévame a One Main Financial!',
          cta2: 'Permanece en Camino Financial',
        },
        form: {
          firstnameError: 'Nombre es requerido',
          lastnameError: 'Apellido es requerido',
          phoneError: 'Número de Teléfono es requerido',
          phoneFormatError: 'Número de Teléfono no es valido',
          emailError: 'Email es requerido',
          emailFormatError: 'Email no es valido',
          loantypeError: 'Tipo de Préstamo es requerido',
          timeInBizError: 'Tiempo en Operación es requerido',
          consentError: 'Consentimiento es requerido',
          firstname: 'Nombre',
          lastname: 'Apellido',
          phone: 'Número de Teléfono',
          email: 'Email',
          loantype: 'Tipo de Préstamo',
          business: 'Préstamo Empresarial',
          personal: 'Préstamo Personal',
          time: 'Tiempo en Operación',
          timeOpt1: 'Menos de 12 meses',
          timeOpt2: 'Más de 12 meses',
          timeOpt3: '1 - 2 Años',
          timeOpt4: '2 - 3 Años',
          timeOpt5: '3 - 4 Años',
          timeOpt6: '4 Años o Más',
          start_app: 'APLICA HOY',

          consent1:
            'Al dar clic en el botón de abajo, después de proporcionar mi número de teléfono y correo electrónico, doy mi consentimiento, reconozco y acepto los ',

          consent4: ' Términos y Condiciones',
          consent5: ' Política de Privacidad, ',
          consent7: ' Acuerdos de comunicación electrónica',
          consent2: ', la ',
          consent3: '',
          consent6: ' y los ',
          consent8: ' de Camino Financial.',
        },

        requirements: {
          title1: 'Familiarízate con lo que necesitas para',
          title2: 'aplicar con nosotros',
          title3: '',
          subtitle1: 'ITIN o SSN',
          description1: 'Debes contar con un',
          subtitle2: 'de $2,500 o más',
          description2: 'Son necessarios ingresos mensuales',
          subtitle3: 'antigüedad de negocio',
          description3: '+ 9 meses de',
          subtitle4: 'con deudas existentes',
          subtitle5: '(sin contar médicas)',
          description4: 'Estar al día',
        },
        ctaBox: {
          title1: 'Acelera el crecimiento de tu negocio',
          description1:
            'En Camino Financial, estamos construyendo un futuro en el que las empresas minoritarias no se vean obstaculizadas por la falta de acceso al capital, dedicándonos a ayudarles a prosperar en sus respectivas industrias.',
          btnText: 'APLICA HOY',
        },

        comparisonTable: {
          title1: '',
          title2: 'Impulsando tu negocio',
          title3: 'con el crédito más adecuado',
          rowHeader1: '¿Acepta ITIN?',
          rowHeader2: 'Acepta sin historial crediticio',
          rowHeader3: 'APR (Tasa de porcentaje anual)',
          colHeader1: 'Camino Financial',
          colHeader2: 'Adelantos de Efectivo',
          colHeader3: 'Líneas de Crédito',
          rowHeader4: 'Terminos de pago',
          rowHeader5: 'Frecuencia de pagos',
          valC2R2: 'Depende del prestamista',
          valC1R3: 'Fijo',
          valC2R3: '50 - 90%',
          valC3R3: '7 - 25%',
          valC1R4: '24 - 36 meses',
          valC2R4: '12 - 24 meses',
          valC3R4: '12 - 60 meses',
          valC1R5: 'Mensual',
          valC2R5: 'Diario o Semanal',
          valC3R5: 'Semanalmente o bisemanalmente',
          footerNote:
            'Nuestros términos de pago superan a los de otros prestamistas y nuestras tasas permanecen fijas. Esto asegura un préstamo más asequible para ti, ya que tus pagos mensuales sean más bajos en comparación con los de los prestamistas que ofrecen tasas variables y plazos de reembolso más cortos.',
        },
        splitSection: {
          title: 'Califica en 5 minutos',
        },

        footer: {
          disclosures: {
            title: 'Aviso legal',
            description:
              'Camino Financial tiene licencia como Salas & Company LLC (NMLS #2186459). Nuestro servicio se limita únicamente a préstamos comerciales o de negocios. Las solicitudes están sujetas a aprobación de crédito. La tasa y los términos pueden variar según su solvencia y están sujetos a cambio. Se aplican tarifas adicionales. En CA, los préstamos se realizan o se organizan bajo la licencia CFL #60DBO-43053. Las licencias de préstamo adicionales que posee Salas & Company LLC incluyen: TX # 2100070774-167840; FL # CF9901463. Las Certificaciones de Desarrollo Comunitario que posee Salas & Company LLC incluyen: Certificación CDFI # 181CE054231; Certificación CDE # 21NMC058716.',
          },
          title1: '¡CONÉCTATE!',
          description1:
            'Únete a una comunidad de más de de 40,000 personas que reciben consejos dos veces a la semana para hacer crecer su negocio.',
          signupPlaceholder: 'Introduce tu correo electrónico',
          signup: 'REGISTRARSE',
          title2: 'UNA COMPAÑÍA CON UNA MISIÓN',
          description2:
            'La misión de Camino Financial es favorecer el crecimiento económico en comunidades de ingresos bajos a moderados ayudando a las pequeñas empresas marginadas a crear nuevos trabajos y crecer de manera sostenible.',
          link2: {
            label: 'Lee nuestra historia',
            url: `${process.env.GATSBY_CAMINO_URL}/es/about-us/`,
          },
          title3: 'EXPLORAR',
          sitemap: {
            link1: {
              label: 'Historias de Éxito',
              url: `${process.env.GATSBY_CAMINO_URL}/success-stories/`,
            },
            link2: {
              label: 'Aplicar Hoy',
              url: `${process.env.GATSBY_CAMINO_URL}/get-me-a-loan10/`,
            },
            link3: {
              label: 'Préstamos de Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/small-business-loans/`,
            },
            link4: {
              label: 'Calculadora de Préstamos para Negocios',
              url: `${process.env.GATSBY_CAMINO_URL}/business-loan-calculator/`,
            },
            link5: {
              label: 'Mapa del Sitio',
              url: `${process.env.GATSBY_CAMINO_URL}/sitemap/`,
            },
            link6: {
              label: 'Seguridad',
              url: `${process.env.GATSBY_CAMINO_URL}/security/`,
            },
            link7: {label: '', url: ''},
            link8: {
              label: 'Iniciar Sesión',
              url: `${process.env.GATSBY_CAMINO_URL}/signin`,
            },
            link9: {
              label: 'Opiniones de Camino',
              url: `${process.env.GATSBY_CAMINO_URL}/es/reviews/`,
            },
            link10: {
              label: 'Quiénes Somos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/sobre-nosotros/`,
            },
            link11: {
              label: 'Contactanos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/contactanos/`,
            },
            link12: {
              label: 'Recursos',
              url: `${process.env.GATSBY_CAMINO_URL}/es/recursos/`,
            },
            link13: {
              label: 'Carreras',
              url: 'https://www.linkedin.com/company/camino-financial/jobs',
            },
            link14: {
              label: 'Blog de Tecnología',
              url: 'https://techblog.caminofinancial.com/',
            },
            link15: {
              label: 'Do Not Sell My Info',
              url:
                'https://privacyportal.onetrust.com/webform/e2cf87e2-04b0-4669-a94d-298d7fa99bbb/c315c398-738e-4cfd-8544-a7905247065a',
            },
          },
          bottomFotter: {
            link1: {
              label: 'Términos de uso',
              url: `${process.env.GATSBY_CAMINO_URL}/es/terms-of-use/`,
            },
            link2: {
              label: 'Política de Privacidad',
              url: `${process.env.GATSBY_CAMINO_URL}/es/privacy-policy/`,
            },
            link3: {
              label: 'CCPA',
              url: `${process.env.GATSBY_CAMINO_URL}/es/ccpa-policy/`,
            },
          },
          rightsReserved:
            '2021 por Camino Financial, Inc. Todos los derechos reservados. Camino Financial es un prestamista y corredor de finanzas con Îlicencia en California bajo su filial Salas & Company LLC.',
        },

        faq: {
          title: 'Preguntas frecuentes',
          question1: {
            question: '1. ¿Cómo puedo obtener un préstamo para negocios?',
            answer: '',
          },
          question2: {
            question: '2. ¿Cuáles son los requisitos?',
            answer: '',
          },
          question3: {
            question:
              '3. ¿Qué tipo de préstamo para negocio ofrece Camino Financial?',
            answer: '',
          },
          question4: {
            question:
              '4. ¿Debería aplicar para un préstamo de Camino Financial?',
            answer: '',
          },
          question5: {
            question:
              '5. ¿Puedo obtener un préstamo para negocio si solo tengo un ITIN?',
            answer: '',
          },
          question6: {
            question:
              '6. ¿Puedo solicitar un préstamo para negocio si no tengo historial crediticio?',
            answer: '',
          },
          question7: {
            question:
              '7. ¿La solicitud de Camino Financial afecta mi puntaje crediticio?',
            answer: '',
          },
          question8: {
            question:
              '8. ¿Necesito colateral para solicitar un préstamo de Camino Financial?',
            answer: '',
          },
          question9: {
            question: '9. ¿Cuáles son sus tasas de interés?',
            answer: '',
          },
          question10: {
            question: '10. ¿Es seguro el proceso de financiamiento?',
            answer: '',
          },
        },
      },
    },
  },
  en: {
    translation: {
      siteMetadata: {
        title: 'Get Me A Loan',
        titleTemplate: 'Camino Financial',
      },
      solopreneur: {url: 'https://www.caminofinancial.com/solopreneur-loan/'},
      gml10: {
        topNav: {
          link1: {
            label: 'COVID-19 Relief',
            url: `${process.env.GATSBY_CAMINO_URL}/covid-19/`,
          },
          link1nav: {
            link1: {
              label: 'SBA Paycheck Protection Program (PPP)',
              url: `${process.env.GATSBY_CAMINO_URL}/the-sba-paycheck-protection-program-ppp-covid-19-relief/`,
            },
            link2: {
              label: 'Economic Injury Disaster Loans (EIDL)',
              url: `${process.env.GATSBY_CAMINO_URL}/the-sba-economic-injury-disaster-loans-eidl-covid-19-relief/`,
            },
            link3: {
              label: 'More COVID-19 Relief',
              url: `${process.env.GATSBY_CAMINO_URL}/covid-19/`,
            },
          },
          link2: {label: 'Business Loans', url: 'http:'},
          link3: {label: 'Business Resources', url: 'http:'},
          link3nav: {
            link1: {
              label: 'Business Funding',
              url: `${process.env.GATSBY_CAMINO_URL}/category/business-funding-2/`,
            },
            link2: {
              label: 'Accounting & Cash Flow',
              url: `${process.env.GATSBY_CAMINO_URL}/category/accounting-cash-flow/`,
            },
            link3: {
              label: 'Starting a Business',
              url: `${process.env.GATSBY_CAMINO_URL}/category/starting-a-business-2/`,
            },
            link4: {
              label: 'Marketing & Sales',
              url: `${process.env.GATSBY_CAMINO_URL}/category/marketing-sales/`,
            },
            link5: {
              label: 'Management & Lifestyle',
              url: `${process.env.GATSBY_CAMINO_URL}/category/management-lifestyle/`,
            },
            link6: {
              label: 'Technology & Operations',
              url: `${process.env.GATSBY_CAMINO_URL}/category/technology-and-operations/`,
            },
            link7: {
              label: 'Small Business News',
              url: `${process.env.GATSBY_CAMINO_URL}/category/small-business-news/`,
            },
            link8: {
              label: 'U.S. Latino Small Business Survey',
              url: `${process.env.GATSBY_CAMINO_URL}/latino-small-business-survey/`,
            },
          },
          link4: {label: 'Success Stories', url: 'http:'},
          link5: {label: 'About Us', url: 'http:'},
          link6: {
            label: 'SIGN IN',
            url: `${process.env.GATSBY_MY_CAMINO_URL}/signin`,
          },
          link7: {label: 'GET A QUOTE', url: 'http:'},
          link8: {label: 'ESPAÑOL', url: 'http:'},
        },
        hero: {
          title1: 'Perfect Loans,',
          title2: 'Predictable Payments',
          subtitle:
            'We support businesses and entrepreneurs with only an ITIN, eliminating the need for collateral or guarantees, and even accepting those without a credit history.',
          media_presence: 'As seen in the media',
        },
        reviews: {
          title: 'Generational prosperity powered by AI',
        },
        calculator: {
          title: 'Forecast Your Payments',
          loan_amount: 'LOAN AMOUNT',
          payment_term: 'PAYMENT TERM',
          monthly_interest_rate: 'MONTHLY INTEREST RATE',
          monthly: 'MONTHLY',
          months: 'MONTHS',
          total_cost_of_loan: 'TOTAL COST OF THE LOAN',
          funding_fee: 'FUNDING FEE',
          documentation_fee: 'DOCUMENTATION FEE',
          closing_fee: 'CLOSING FEE',
          total_interest_paid: 'TOTAL INTEREST PAID',
          see_more: 'VIEW MORE INFO',
          see_less: 'VIEW LESS INFO ',
          check_if_you_qualify: 'CHECK IF YOU QUALIFY',
        },
        stats: {
          title1: 'Empowering economic development',
          title2: 'through',
          title3: '',
          number1: '$200M+',
          description1: 'of loans funded',
          number2: '10,341+',
          description2: 'to 90% of overlooked entrepreneurs',
          number3: ' $109M+',
          description3: 'in incremental revenue for borrowers',
          number4: ' $19.5M',
          description4: 'saved in interest and fees',
        },
        feats: {
          title1: 'We’re not like most banks,',
          title2: 'we accept',
          feature1: 'Business with no credit history',
          feature2: 'Self-employed',
          feature3: 'Informal businesses',
          feature4: 'Cash-heavy businesses',
          feature5: '',
        },
        partnerModal: {
          title: 'You will be Redirected to One Main Financial',
          subtitle:
            "Based on your loan request, we've found that a personal loan is the best fit for you.",
          description:
            "We've partnered with One Main Financial for the fulfillment of personal loans and will now redirect you to their site.",
          description2:
            "Disclosure: Camino Financial receives compensation from One Main Financial for leads funded by this partner. Camino Financial does not engage in One Main Financials personal lending activities and does not in any way originate personal loans. The responsibility for credit decisions, including loan approval, interest rates, and terms offered, rests solely with One Main Financial. Approval decisions vary depending on the request, the borrower's financial situation, and the criteria established by One Main Financial. Not all individuals sent to One Main Financial will meet the eligibility criteria for the advertised rates and terms.",
          cta1: 'Continue to One Main Financial Website',
          cta2: 'Stay On Camino Financial',
        },
        form: {
          firstnameError: 'First name is required',
          lastnameError: 'Last name is required',
          phoneError: 'Phone is required',
          phoneFormatError: 'Phone format is not valid',
          emailError: 'Email is required',
          emailFormatError: 'Email format is not valid',
          loantypeError: 'Loan type is required',
          timeInBizError: 'Time in business is required',
          consentError: 'Consent is required',
          firstname: 'First Name',
          lastname: 'Last Name',
          phone: 'Mobile Number',
          email: 'Email Address',
          loantype: 'Loan Type',
          business: 'Business Loan',
          personal: 'Personal Loan',
          time: 'Time in Business',
          timeOpt1: 'Under 12 months',
          timeOpt2: 'More than 12 months',
          timeOpt3: '1 - 2 Years',
          timeOpt4: '2 - 3 Years',
          timeOpt5: '3 - 4 Years',
          timeOpt6: '4 Years or more',
          start_app: 'START APPLICATION',
          consent1: `By providing my phone number & email and clicking the button below, I consent, acknowledge, and agree to Camino Financial's`,
          consent4: ' Terms & Conditions, ',
          consent5: ' Privacy Policy, ',
          consent7: ' Electronic Communication Agreements.',
          consent2: '',
          consent3: '',
          consent6: ' and ',
          consent8: '',
        },

        requirements: {
          title1: 'Familiarize yourself with our minimum requirements to apply',
          title2: '',
          title3: '',
          subtitle1: 'SSN or ITIN',
          description1: 'You should have a',
          subtitle2: 'over $2,500 or more',
          description2: 'Monthly revenue of',
          subtitle3: 'in business',
          description3: '+ 9 months',
          subtitle4: 'credit obligations',
          subtitle5: '(not including medical debt)',
          description4: 'Current on outstanding',
        },
        ctaBox: {
          title1: 'Accelerate your business growth',
          description1:
            'At Camino Financial, we are building a future where minority businesses are not hindered by lack of access to capital, dedicating ourselves to helping them thrive in their respective industries.We are dedicated to helping minority business succeed. We believe access to capital is one of the greatest challenges for minority businesses.',
          btnText: 'START APPLICATION',
        },
        comparisonTable: {
          title1: 'Access',
          title2: 'Affordable Credit',
          title3: 'Tailored to Your Business Needs',
          rowHeader1: 'Accept ITIN?',
          rowHeader2: 'No credit history accepted?',
          rowHeader3: 'APR',
          rowHeader4: 'Payment terms',
          rowHeader5: 'Payment frequency',
          colHeader1: 'Camino Financial',
          colHeader2: 'Other Private Lenders',
          colHeader3: 'Lines of Credit',
          valC2R2: 'Depends on the lender',
          valC1R3: 'Fixed',
          valC2R3: '50 - 90%',
          valC3R3: '7 - 25%',
          valC1R4: '24 - 36 months',
          valC2R4: '12 - 24 months',
          valC3R4: '12 - 60 months',
          valC1R5: 'Monthly',
          valC2R5: 'Daily or Monthly',
          valC3R5: 'Weekly or Bi-weekly',
          footerNote:
            'We outperform other lenders with our superior payment terms and fixed rates, making your loan more affordable as your monthly payments will be lower.',
        },
        splitSection: {
          title: 'Pre-qualify in 5 minutes',
        },

        footer: {
          disclosures: {
            title: 'Disclosures',
            description:
              'Camino Financial is licensed as Salas & Company LLC (NMLS #2186459). Our service is limited to commercial or business loans only. Applications are subject to credit approval. Rate and terms may vary based on your creditworthiness and are subject to change. Additional fees apply. In CA, loans are made or arranged under CFL License #60DBO-43053. Additional lending licenses held by Salas & Company LLC include: TX # 2100070774-167840; FL # CF9901463. Community Development Certifications held by Salas & Company LLC include: CDFI Certification # 181CE054231; CDE Certification # 21NMC058716.',
          },
          title1: 'STAY CONNECTED',
          description1:
            'Join a community of over 40,000 people who receive bi-weekly tips on growing your business.',
          signupPlaceholder: 'Enter your email address',
          signup: 'SIGN UP',
          title2: 'MISSION-DRIVEN COMPANY',
          description2:
            'Camino Financial’s mission is to catalyze economic growth within low-to-moderate income communities by empowering small businesses to create new jobs and grow sustainably.',
          link2: {
            label: 'Read Our Story ',
            url: `${process.env.GATSBY_CAMINO_URL}/about-us/0/`,
          },
          title3: 'NAVIGATE',
          sitemap: {
            link1: {
              label: 'Success Stories',
              url: `${process.env.GATSBY_CAMINO_URL}/success-stories/`,
            },
            link2: {
              label: 'Loan Application',
              url: `${process.env.GATSBY_CAMINO_URL}/get-me-a-loan10/`,
            },
            link3: {
              label: 'Business Loans',
              url: `${process.env.GATSBY_CAMINO_URL}/small-business-loans/`,
            },
            link4: {
              label: 'Business Loan Calculator',
              url: `${process.env.GATSBY_CAMINO_URL}/business-loan-calculator/`,
            },
            link5: {
              label: 'Sitemap',
              url: `${process.env.GATSBY_CAMINO_URL}/sitemap/`,
            },
            link6: {
              label: 'Security',
              url: `${process.env.GATSBY_CAMINO_URL}/security/`,
            },
            link7: {label: '', url: ''},
            link8: {
              label: 'Sign In',
              url: `${process.env.GATSBY_CAMINO_URL}/signin`,
            },
            link9: {
              label: 'Reviews',
              url: `${process.env.GATSBY_CAMINO_URL}/reviews/`,
            },
            link10: {
              label: 'About Us',
              url: `${process.env.GATSBY_CAMINO_URL}/about-us/`,
            },
            link11: {
              label: 'Contact Us',
              url: `${process.env.GATSBY_CAMINO_URL}/contact-us/`,
            },
            link12: {
              label: 'Business Resources',
              url: `${process.env.GATSBY_CAMINO_URL}/resources/`,
            },
            link13: {
              label: 'Careers',
              url: 'https://www.linkedin.com/company/camino-financial/jobs/',
            },
            link14: {
              label: 'Tech Blog',
              url: 'https://techblog.caminofinancial.com/',
            },
            link15: {
              label: 'Do Not Sell My Info',
              url:
                'https://privacyportal.onetrust.com/webform/e2cf87e2-04b0-4669-a94d-298d7fa99bbb/c315c398-738e-4cfd-8544-a7905247065a',
            },
          },
          bottomFotter: {
            link1: {
              label: 'Terms of Use',
              url: `${process.env.GATSBY_CAMINO_URL}/terms-of-use/`,
            },
            link2: {
              label: 'Privacy Policy',
              url: `${process.env.GATSBY_CAMINO_URL}/privacy-policy/`,
            },
            link3: {
              label: 'CCPA',
              url: `${process.env.GATSBY_CAMINO_URL}/ccpa-policy/`,
            },
          },
          rightsReserved:
            '2021 by Camino Financial, Inc. All Rights Reserved. Camino Financial is a Licensed Finance Lender & Broker in California under its subsidiary Salas & Company LLC.',
        },

        faq: {
          title: 'Frequently Asked Questions',
          question1: {
            question: '1. How can I get a business loan?',
            answer: '',
          },
          question2: {
            question: '2. What are the requirements?',
            answer: '',
          },
          question3: {
            question:
              '3. What type of business loan does Camino Financial offer?',
            answer: '',
          },
          question4: {
            question: '4. Should I apply for a business loan with CF?',
            answer: '',
          },
          question5: {
            question: '5. Can I get a business loan if I only have an ITIN?',
            answer: '',
          },
          question6: {
            question:
              '6. Can I apply for a business loan if I don’t have a credit history?',
            answer: '',
          },
          question7: {
            question:
              '7. Does the Camino Financial application affect my credit score?',
            answer: '',
          },
          question8: {
            question:
              '8. Do I need collateral to apply for a Camino Financial loan?',
            answer: '',
          },
          question9: {
            question: '9. What are your interest rates?',
            answer: '',
          },
          question10: {
            question: '10. Is the lending process safe?',
            answer: '',
          },
        },
      },
    },
  },
};

export default translations;
